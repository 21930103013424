<template>
  <v-app>
    <v-img v-show="showLogin" src="./assets/login.jpg" height="100%" max-height="100%">
      <v-main style="height: 75%;">
        <v-container fluid fill-height>

          <v-row align="center" justify="center">
            <v-col cols="12" sm="10" md="7" lg="5" xl="4">

              <APIError></APIError>

              <v-card class="elevation-12">

                <v-toolbar color="primary" dark flat>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>Compado - Adtech</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text align="center" justify="center">
                  <g-identity-signin-button @success="onIdentitySignin" />
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>

        </v-container>
      </v-main>
    </v-img>
  </v-app>
</template>

<script>
import { ax } from '@/main.js'
import auth from '@/auth'

export default {
  name: 'GoogleLogin',

  data () {
    return {
      apiError: null,
      destURL: '/home',
      googleTokenSigninEndpoint: '/google-identity-signin',
      sessionTokenLoginEndpoint: '/session-token-login',
      showLogin: false,
      tokenSignin: {
        id_token: ''
      }
    }
  },
  components: {
    APIError: () => import('@/components/APIError'),
    GIdentitySigninButton: () => import('@/components/GIdentitySigninButton'),
  },

  methods: {
    onIdentitySignin: function (google_token) {
      //console.log(googleUser.getAuthResponse().id_token)

      this.tokenSignin.id_token = google_token.credential
      var body = JSON.stringify(this.tokenSignin)

      // send the google token to backend to get session token
      this.$http.post(this.googleTokenSigninEndpoint, body).then(resp => {

        // replace local token
        localStorage.removeItem('id_token')
        localStorage.setItem('id_token', resp.data.data.token)

        // set token as default auth header in axios
        ax.defaults.headers.common.Authorization = 'Bearer ' + resp.data.data.token

        this.loginSuccess(resp.data.data)
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.googleTokenSigninEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    loginSuccess: function (respData) {
      // flag user as authenticated, write user props
      auth.user.authenticated = true
      auth.user.name = respData.user_name
      auth.user.initials = respData.user_initials
      auth.user.id = respData.user_id
      auth.user.permissions = respData.permissions_csv.split(',')

      // clear API error if any
      this.$store.commit('system/setAPIError', null)

      // redirect
      if (this.$route.query.to) {
        this.destURL = this.$route.query.to
      }
      this.$router.push({ path: this.destURL })
    },

    tokenLogin: function () {
      this.$http.post(this.sessionTokenLoginEndpoint).then(resp => {
        this.loginSuccess(resp.data.data)
      })
      .catch(errResp => {
        this.showLogin = true

        // if error is caused by user having an expired token in his localStorage
        if (errResp.response.data.err_description === 'token not found') {

          // don't show alert, just delete old token
          localStorage.removeItem('id_token')
        } else {

          // don't slow alert for session expired
          if (errResp.response.data.err_description !== 'session expired') {
            this.$store.commit('system/setAPIError', { method: 'POST', url: this.sessionTokenLoginEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
          }
        }
      })
    },
  },

  created: function () {
    // if a local storage token is present, and to= param is set, attempt a token login. Intended for when a user opens a new tab or refreshes page
    // but does not attempt a token login after logout action
    //console.log(this.$route.path + ' ' + this.$route.query.to)
    if (localStorage.getItem('id_token') && this.$route.query.to) {
      ax.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('id_token')
      this.tokenLogin()
    } else {
      this.showLogin = true
    }
  }
}
</script>

